/* 'dark':['#FFFADD','#973A5E','#392581','#19A09B','#C2690D','#572576','#1b1523','yellow'] */

.progressBarContainer {
    background-color: #FFFADD;
    border-radius: .25rem;
    width: 95%;
    height:2.5rem;
    margin: auto;
    margin-top: 1rem;
}

.progressBarPercentiles{
    width:100%;
    height:2.5rem;
    /* margin: auto; */
    position: relative;
    border-radius: .25rem;
    /* border: 1px solid blue; */
}

.progressBarTop10{
    background-color:#19A09B;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    height: 2.5rem;
    position: relative;
}


.progressBarTop25{
    background-color:#C2690D;
    height: 2.5rem;
    position: absolute;
    top:0%;
}

.progressBarTop50{
    background-color:#572576;
    height: 2.5rem;
    position: absolute;
    top:0%;
}


.progressBarUserBestScorePlot{
    background-color:#000;
    /* height: 2.5rem; */
    width:2.5%;
    border: 1px solid #fff;
    border-radius: 80%;
    height: 30%;
    position: absolute;
    top:10%;
    left:5%;
    /* margin: auto; */
}