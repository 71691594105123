.modal {
    position: absolute;
    top: 75%;
    left: 50%;
    transform:translate(-50%, -50%);
    width:100%;
    height:auto;
    margin:0.25rem;
    padding: .25rem;
    border: 2px solid #fff;
    border-radius: .5rem;
    /* border: 2px solid black; */
    background: rgba(0,0,0,0.75);
    color:black;
}

.title {
    margin:0rem;
    padding:0.25rem;
    font-size: 2rem;
    color: #fff;
}

.bodyText {
    margin:0rem;
    padding:0.25rem;
    font-size: 1.25rem;
    color: #fff;
}

.button {
    background: rgba(0,0,0,0.75);
    border: .1rem solid #fff;
    padding: 0.5rem;
    margin: .25rem;
    border-radius: .25rem;
    color: #fff;
    /* font-weight: bold; */
    cursor: pointer;
    font-size: 1.25rem;
}

.button:hover {
    background: #c23866;
    color: #fff;
}

.modalAdContainer{
    font-size: 1.25rem;
    color: #fff;
    height:20rem;
    border:2px solid #fff;
    margin: .5rem .25rem;
    display: flex;
}

.modalInnerAdContainer{
    margin:auto;
}