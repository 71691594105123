.tile {
    transition: box-shadow .3s;
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
    border-radius: 15%;
    /* box-shadow: inset 2px 2px 2px 2px rgba(255,255,255, .45), inset -2px -2px 2px 2px rgba(0,0,0, .25); */
    box-shadow: inset .05rem .05rem .05rem .05rem rgba(255,255,255, .45);
  }

.tile.inPlay:hover {
    
    box-shadow: 0 0 0.75rem rgba(164, 234, 34, .9),
        inset 2px 2px 2px 2px rgba(255,255,255, .45), 
        inset -2px -2px 2px 2px rgba(0,0,0, .25); 
}

.tile.inPlay.selected {
    box-shadow: inset .2rem .2rem .2rem .2rem rgba(0,0,0,0.7), 
        0 0 0.5rem #FFFF00FF
}

.tile.outOfBounds {
    background: none;
    border: none;
    box-shadow: none;
}

.tile.empty {
    box-shadow: inset 4px 4px 8px 3px rgba(0,0,0,0.5);
}

.tile.empty.available {
    background: repeating-linear-gradient(
        45deg,
        #393938,
        #939292 10%,
        #FFFADD 5%,
        #FFFADD 22%
    )
}