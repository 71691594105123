.App{
  max-width: 100vw;
  /* max-height: 70vh; */
  display: flex;
  /* border: 2px solid red; */
  justify-content: center;
}

.pageContent{
  /* border: 2px solid white; */
  max-width: 73rem;
  max-height: 95vh;
  /* min-width: 70rem; */
  display: flex;
  justify-content: center;
}

.left{
  width:30%;
  margin: 1%;
  padding: 0;
  /* display: flex; */
}

.menuBarContainer{
  /* border: 2px solid red; */
  /* min-height: 60rem; */
  position: relative;
}

.menuBar{
  width: auto;
  height: auto;
  /* border: 2px solid yellow; */
  position: absolute;
  top: 60%;
  left: 50%;
  transform:translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

button.menuButton{
  width: 14rem;
  height: 2rem;
  background: rgba(0,0,0,0.75);
  border: .1rem solid #fff;
  padding: 0.25rem;
  margin: .25rem;
  border-radius: .25rem;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.25rem;
}

button.menuButton:hover {
  background: #c23866;
  color: #fff;
}

img.menu_bar_img{
  padding: 0%;
  width: 98%;
  height: auto;
  /* min-height: 50rem; */
  max-height: 175vh;
}

img.menu_bar_img:hover{
  filter:hue-rotate(-90deg);
}

.middle{
  width:40%;
  min-width: 26.5rem;
  /* max-width: 98vw; */
  margin: 1%;
  padding: 0;
}

.topBanner{
  display:flex;
  flex-direction: row;
  border-bottom: 2px solid #e235a4;
  justify-content: center;
}

.topBanner:hover{
  color: #00C3E3;
}

.siteTitle{
  margin:1rem auto;
  font-size: 2.5rem;
  flex-shrink: 0;
}

img.logo_icon{
  margin:auto;
  height: 4rem;
  width: auto;
  border: 2px solid #FDF3FF;
  border-radius: .5rem;
}

.right{
  width:30%;
  min-width: 19.875rem;
  margin: 1%;
  padding: 0;
  border: 2px solid white;
  display: flex;
}

.futureAdLoc{
  margin:auto;
  /* border: 2px solid white; */
}

