.aboutContainer{

}
            
.aboutTitle{
    margin-top: 5rem;
    font-size: 2rem;
    font-weight: 700;
}
                       
.aboutBody{
    margin-top: 2rem;
    font-size: 1rem;
}