@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lalezar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.solitare-game-wrapper {
  height:100%;
  position:relative;
}

.solitare-tile-grid {

    display: grid;
    row-gap: .3rem;
    column-gap: .3rem;

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    padding: 2%;

  }

  .gameTitle{
    height: 4rem;
    font-family: 'Lalezar', cursive;
    text-align: center;
    color: aliceblue;
    font-size: 3rem;
    /* padding: 0rem; */
    /* margin:.5rem 0rem 0rem 0rem; */
    /* margin-top:.5rem; */
    /* border: 2px solid yellow; */
    border-bottom: 2px solid whitesmoke;
  }

  .timeBanner {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 1.2rem;


  }
  
  .gameDate{
    margin: auto 1rem;
    font-family: 'Open Sans', sans-serif;
    /* border: 2px solid yellow; */
  }
  
  .timeRemaining{
    text-align: left;
    /* border: 1px solid green; */
    display: flex;
    justify-content: space-between;
  }

  .nextBoardIn{
    margin:auto 0.25rem auto auto;
    font-family: 'Open Sans', sans-serif;
  }

  .timeTicker{
    font-family: 'Chivo Mono', monospace;
    margin: .25rem 0.5rem .25rem 0rem;
    max-width: 6rem;
    padding: .25rem .5rem;
    background: #FFFFF0;
    border-radius: .25rem;
    border: 1px solid black;
    color: black;
  }

  .bottomStatBanner {
    display: flex;
    justify-content: flex-start;
  }
  .tilesRemaining {
    margin:.25rem 1rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
  }
